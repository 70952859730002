import { useSelector } from 'react-redux';
import Heading1 from '../../components/Heading1';
import ExternalLink from '../../components/ExternalLink';
import SubHeadline from '../../components/SubHeadline';
import HookIdeaSection from "./HookIdeaSection";

function AnalysisSection() {
  const analysis = useSelector(state => state.analysis);
  // const [screenshotGifs, setScreenshotGifs] = useState([]);
  //
  // const loadImages = useCallback((screenshotSeries) => {
  //   for (const imageUrls of screenshotSeries) {
  //     if (imageUrls) {
  //       let images = [];
  //       let loadCount = 0;
  //
  //       imageUrls.forEach(url => {
  //         const img = new Image();
  //         img.crossOrigin = "anonymous";
  //         img.onload = () => {
  //           images.push({
  //             url: url,
  //             width: img.width,
  //             height: img.height
  //           });
  //           loadCount++;
  //
  //           if (loadCount === imageUrls.length) {
  //             generateGif(images);
  //           }
  //         };
  //         img.src = url;
  //       });
  //     } else {
  //       setScreenshotGifs(state => [...state, null]);
  //     }
  //   }
  // }, []);
  //
  // const generateGif = (images) => {
  //   // Calculate dimensions for the GIF
  //   // Example: Using the dimensions of the first image
  //   const gifWidth = images[0].width;
  //   const gifHeight = images[0].height;
  //
  //   createGIF({
  //     'images': images.map(image => image.url),
  //     'gifWidth': gifWidth,
  //     'gifHeight': gifHeight,
  //     frameDuration: 5,
  //     sampleInterval: 10,
  //   }, function (obj) {
  //     if (!obj.error) {
  //       setScreenshotGifs(state => [...state, obj.image]);
  //     }
  //   });
  // };
  //
  // useEffect(() => {
  //   if (analysis.screenshotSeries) {
  //     loadImages(analysis.screenshotSeries);
  //   }
  // }, [loadImages, analysis.screenshotSeries]);

  return (
    <div className="flex flex-col items-center max-w-3xl w-full mb-4">
      <Heading1 className="my-2 mb-4 sm:mb-8 md:w-[32rem]">Here are Your YouTube Ad <span className="underline">Creative Scores & Analysis</span>:</Heading1>
      <div className="text-stack text-xl my-4 w-full">
        <p>At VidTao, we look at the 7 million plus YouTube ads in our database and love uncovering what’s working and hypothesizing on WHY it’s working.</p>
        <p>(btw - go here to <ExternalLink href="https://app.vidtao.com?source=creative_analysis_app">claim a free trial of VidTao</ExternalLink> in case you haven’t yet checked it out…)</p>
        <p>We analyzed your YouTube video ad AND a similar high-performing YouTube ad that you selected, and have some insights to share with you.</p>
        <p className="font-bold">Good news! There are some big opportunities for improving your ad to make it significantly better.</p>
        <p>We’ll be sharing how your ad scored, as well as some specific ways to improve it.</p>
        <p>Let’s start with your ad’s first 10 seconds.</p>
        <SubHeadline>Why Your Ad’s “Hook” is Crucial…</SubHeadline>
        <p>As a reminder, <span className="underline">here’s why the first 10 seconds of your YouTube ad - what we call the ad’s “HOOK” - are so <span className="font-bold">crucial:</span></span></p>
      </div>
      <div className="p-6 my-4 max-w-2xl w-full bg-[#FBF9F0] border-2 border-[#222222] rounded-[15px]">
        <p className="font-bold text-center">⚠️ Why Your YouTube Ad’s 1st 10 seconds Matter:</p>
        <p className="mt-4">⏱️ <span className="font-bold">Your Ad’s First 5 Seconds:</span> acts as a “hook” for the user’s attention and get them to stay past when the “skip ad” button becomes clickable</p>
        <p className="mt-4">⏱️ <span className="font-bold">Your Ad’s First 10 Seconds:</span> Get viewers to stick around past 10 seconds acts as an “ad quality” signal to Google that will drive down your cost of traffic (and drive down your CPA as a result)</p>
      </div>
      <p>(Want more explanation on WHY this is all the case? We go into way more detail on all this in a blog post we did here)</p>

      {analysis["result"] && <>
        {analysis["result"]["user_ad"] && <HookIdeaSection ad={analysis["result"]["user_ad"]} title="Your Ad" />}
        {analysis["result"]["top_similar_ads"] && analysis["result"]["top_similar_ads"].map((ad, index) => <HookIdeaSection key={index} ad={ad} title={`Top Similar Ad`} />)}
        {/*{analysis["result"]["random_ad"] && <HookIdeaSection ad={analysis["result"]["random_ad"]} title="Wild Card! Random top Direct Response Ad" />}*/}
      </>}

      {/*{analysis["result"] && <>*/}
      {/*  {analysis["result"]["user_ad"] && <>*/}
      {/*    <div className="text-stack text-xl my-4 w-full">*/}
      {/*      <p className="my-6">Let’s take a look at your ad’s Hook:</p>*/}
      {/*      {analysis["result"]["user_ad"]["video_info_item"] && <VideoDetailsCheckbox adVideo={analysis["result"]["user_ad"]["video_info_item"]} />}*/}
      {/*      <SubHeadline>🛡️Your Ad’s 🛡️“Skip Shield” Rating (00:00 - 00:05)…</SubHeadline>*/}
      {/*      <p>Let’s start by taking a look at the first 5 seconds of your ad, something we call your ad’s “Skip Shield”:</p>*/}
      {/*      <p>Get this part of your ad right, and you’ll prevent as many people from hitting that “Skip Ad” button as possible.</p>*/}
      {/*      <p>And again, the more viewers you get to watch 10 sec + in your video, the higher your engagement rate and the cheaper your cost of traffic will be.</p>*/}
      {/*      <SubHeadline>Let’s take a closer look at your ad’s 🛡️Skip Shield…</SubHeadline>*/}
      {/*      {analysis["result"]["user_ad"]["screenshot_series"] && analysis["result"]["user_ad"]["screenshot_series"].length > 0 && <img className="mt-8 mb-4 max-h-80 object-contain object-left" src={screenshotGifs[0]} alt="the first 10 seconds of the video" />}*/}
      {/*      <p>We’re talking here about <span className="font-bold underline">both</span>:</p>*/}
      {/*      <ul className="text-lg mx-12 mt-8 mb-4 list-disc">*/}
      {/*        <li>🗣️ <span className="font-bold underline">what is said</span> in the video’s audio (i.e. ad copy)</li>*/}
      {/*        <li>👁️ but also <span className="font-bold underline">what you show</span> on the screen, visually</li>*/}
      {/*      </ul>*/}
      {/*      <p>So, how is your ad’s Skip Shield rating??</p>*/}
      {/*    </div>*/}
      {/*    {analysis.hookEvaluationResults[0][0] && <div className="w-full my-4">*/}
      {/*      <p className="text-xl mb-6">Here’s how we scored it:</p>*/}
      {/*      <MultilineText>{analysis.hookEvaluationResults[0][0]}</MultilineText>*/}
      {/*    </div>}*/}
      {/*    <div className="text-stack text-xl my-4 w-full">*/}
      {/*      <p>Ok, given our feedback here, got any ideas on how you can boost that score for your Skip Shield?</p>*/}
      {/*      <p>We’ll have some specific recommendations for you in just a moment.</p>*/}
      {/*      <p>But now let’s take a closer look at what we call your ad’s “Attention Anchor”, which is what happens between seconds 5 and 10 in your YouTube ad.</p>*/}
      {/*      <SubHeadline>⚓ (00:05 - 00:10) Your Ad’s ⚓“Attention Anchor” Score…</SubHeadline>*/}
      {/*      <p>Here are the visuals from your ad’s ⚓ “Attention Anchor” section, from seconds 5 thru 10 in your video:</p>*/}
      {/*      {analysis.screenshotSeries && analysis.screenshotSeries[1].length > 0 && <img className="mt-8 mb-4 max-h-80 object-contain object-left" src={screenshotGifs[1]} alt="from seconds 5 to 10 of the video" />}*/}
      {/*      <p>Remember: When you get viewers to stick around past 10 seconds, Google registers this as an “Engagement” and it acts as an “ad quality” signal to Google that will drive down your cost of traffic (and drive down your CPA as a result)</p>*/}
      {/*    </div>*/}
      {/*    {analysis.hookEvaluationResults[0][1] && <div className="w-full my-4">*/}
      {/*      <p className="text-xl font-bold mb-8">Here’s how we scored your ⚓Attention Anchor:</p>*/}
      {/*      <MultilineText>{analysis.hookEvaluationResults[0][1]}</MultilineText>*/}
      {/*    </div>}*/}
      {/*    <div className="text-stack text-xl my-4 w-full">*/}
      {/*      <p>Ok, got some ideas on how to improve your ad’s “Attention Anchor”?</p>*/}
      {/*      <p>Great.</p>*/}
      {/*    </div>*/}
      {/*    {analysis.hookEvaluationResults[0][2] && <div className="mb-8 w-full">*/}
      {/*      <div className="text-stack text-xl mt-4">*/}
      {/*        <p>We’re going to walk you through 3 of the biggest specific recommendations we have on how you can improve your hook, ASAP.</p>*/}
      {/*        <p>(Plus some examples of how other high-performing YouTube ads have applied these same tactics…)</p>*/}
      {/*        <p>Ready?</p>*/}
      {/*        <SubHeadline>✅✅✅ 3 Custom Tactics to Improve Your Hooks…</SubHeadline>*/}
      {/*        <p>Here are the 3 biggest “Hook” tactics that you can apply to your ads asap to boost results for this all-important first 10 seconds of your ad:</p>*/}
      {/*      </div>*/}
      {/*      {analysis.hookEvaluationResults[0][2].includes("Hook Tactic #") &&*/}
      {/*        [...analysis.hookEvaluationResults[0][2].matchAll(/Hook Tactic #(\d)/g)].map(item => parseInt(item[1]) - 1).map(index => <HookTacticItem key={index} index={index} />)*/}
      {/*      }*/}
      {/*      <div className="text-stack text-xl mt-4 mb-8">*/}
      {/*        <p>Now here are our recommendations on how to APPLY these specific tactics to your ad, immediately…</p>*/}
      {/*        <SubHeadline>🎁🎁🎁 Here are 3 NEW Hooks for you to test…</SubHeadline>*/}
      {/*        <p>Now let’s take a look at how to APPLY these specific tactics to your unique ad.</p>*/}
      {/*        <p>Here are 3 new hooks ideas for you to test:</p>*/}
      {/*      </div>*/}

      {/*      <MultilineText trimEmptyLines={true}>{analysis.hookEvaluationResults[0][2]}</MultilineText>*/}
      {/*    </div>}*/}
      {/*  </>}*/}
      {/*  {analysis.hookEvaluationResults[1] && <>*/}
      {/*    <div className="text-stack text-xl my-4 mt-8 w-full">*/}
      {/*      <p className="font-bold">Comparing Your Ad</p>*/}
      {/*      <p>Now, how did your ad do against that high-performing ad you selected earlier?</p>*/}
      {/*      {analysis.videoInfoItems && analysis.videoInfoItems[1] && <VideoDetailsCheckbox adVideo={analysis.videoInfoItems[1]} />}*/}
      {/*      <p>Let’s take a look at some of the good/bad from this ad when it comes to the hook…</p>*/}
      {/*    </div>*/}
      {/*    {analysis.hookEvaluationResults[1][0] && <div className="w-full my-4">*/}
      {/*      <p className="text-xl mb-8">First, let’s take a look at this ad’s “Skip Shield” rating:</p>*/}
      {/*      <MultilineText>{analysis.hookEvaluationResults[1][0]}</MultilineText>*/}
      {/*    </div>}*/}
      {/*    {analysis.hookEvaluationResults[1][1] && <div className="w-full my-4">*/}
      {/*      <p className="text-xl mb-8">And now, the ad’s “Attention Anchor” score:</p>*/}
      {/*      <MultilineText>{analysis.hookEvaluationResults[1][1]}</MultilineText>*/}
      {/*    </div>}*/}
      {/*  </>}*/}
      {/*</>}*/}

      {/*{analysis.creativeAnalysisTextItems && <>*/}
      {/*  <div className="text-stack text-xl my-4 mt-8 w-full">*/}
      {/*    <p>Ok, hope you got some additional ideas from this high-performer.</p>*/}
      {/*    <p>Now let’s set the hooks of these ads aside and compare them overall, using a formula adapted from <ExternalLink href="https://marketingbullets.com/">Gary Bencivenga</ExternalLink>’s approach. (For those of you who don’t know, Gary is arguably the world’s greatest living copywriter)</p>*/}
      {/*    <p>Ready to evaluate your ad, overall?</p>*/}
      {/*    <SubHeadline>🎯 Your Ad’s Persuasion Score</SubHeadline>*/}
      {/*    <p>Now we’ll evaluate your ad’s overall use of persuasion and compare it versus your competitor’s ad. This is a good way to see any major areas of improvement in the overall ad structure.</p>*/}
      {/*  </div>*/}

      {/*  {analysis.creativeAnalysisTextItems[0] && <div className="w-full my-4">*/}
      {/*    <p className="text-xl mb-4">First, let’s look at your ad:</p>*/}
      {/*    {analysis.videoInfoItems && analysis.videoInfoItems[0] && <VideoDetailsCheckbox adVideo={analysis.videoInfoItems[0]} />}*/}
      {/*    <ReactMarkdown>{analysis.creativeAnalysisTextItems[0]}</ReactMarkdown>*/}
      {/*  </div>}*/}

      {/*  {analysis.creativeAnalysisTextItems[1] && <div className="w-full my-4">*/}
      {/*    <p className="text-xl mb-4">Next, let’s look at the high-performing YouTube ad you selected:</p>*/}
      {/*    {analysis.videoInfoItems && analysis.videoInfoItems[1] && <VideoDetailsCheckbox adVideo={analysis.videoInfoItems[1]} />}*/}
      {/*    <ReactMarkdown>{analysis.creativeAnalysisTextItems[1]}</ReactMarkdown>*/}
      {/*  </div>}*/}

      {/*  <div className="text-stack text-xl my-4 w-full">*/}
      {/*    <p>See any big opportunities to improve your ad?</p>*/}
      {/*    <p>Go ahead and apply them to your ad, and let us know how it goes!</p>*/}
      {/*  </div>*/}
      {/*</>}*/}

      <div className="text-stack text-xl my-4 w-full">
        <SubHeadline>Claim Your Free YouTube Ad Brainstorm Session</SubHeadline>
        <p>Want to brainstorm ways to scale your business with YouTube ads (or other video traffic channels)? Go ahead and <ExternalLink href="https://inceptly.com/call?source=creative_analysis_app_report">schedule a free YouTube ad brainstorm chat with our team here.</ExternalLink></p>
        <SubHeadline>Explore VidTao’s Library of 7 million+ YouTube ads & Landing Pages</SubHeadline>
        <p>We have over 7 million unlisted YouTube ads (and landing pages) indexed inside our VidTao YouTube ad competitive intelligence tool. Want to try it, 100% free? <ExternalLink href="https://app.vidtao.com?source=creative_analysis_app">sign up here for free 7 day trial access</ExternalLink>.</p>
        <p>And if you’ve got any other questions, go ahead and message us at info@vidtao.com to let us know your thoughts!</p>
        <p>Best wishes,</p>
        <p>The VidTao Team</p>
      </div>
    </div>
  );
}

export default AnalysisSection;
