import { createSlice } from '@reduxjs/toolkit';
import { submitEmail, getTopSimilarAdVideos, buildReport } from '../index';
import { LANDING_STEP } from '../../constants';

const defaultErrorMessage = 'Oops... Something went wrong. Please try again later.';

const landingSlice = createSlice({
  name: 'landing',
  initialState: {
    currentStep: LANDING_STEP.INTRO,
    isWaiting: false,
    errorMessage: null,
    similarAdVideos: null,
    reportId: null
  },
  reducers: {
    landingNextStep: (state, action) => {
      if (state.currentStep < LANDING_STEP.REPORT_AVAILABLE) {
        state.currentStep += 1
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(submitEmail.pending, (state, action) => {
      state.isWaiting = true;
      state.errorMessage = null;
    });
    builder.addCase(submitEmail.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        switch (data['status']) {
          case 'ok': state.currentStep = LANDING_STEP.URL_INPUT; break;
          case 'building_report': state.currentStep = LANDING_STEP.BUILDING_REPORT; break;
          case 'report_available': state.currentStep = LANDING_STEP.REPORT_AVAILABLE; break;
          default: state.errorMessage = defaultErrorMessage; break;
        }
      } else if (error) {
        state.errorMessage = error.error;
      } else {
        state.errorMessage = "No data in the response.";
      }
      state.isWaiting = false;
    });
    builder.addCase(submitEmail.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.isWaiting = false;
    });

    builder.addCase(getTopSimilarAdVideos.pending, (state, action) => {
      state.isWaiting = true;
      state.similarAdVideos = null;
      state.errorMessage = null;
    });
    builder.addCase(getTopSimilarAdVideos.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        state.similarAdVideos = data;
        state.currentStep = LANDING_STEP.SELECT_COMPARISON_AD;
      } else if (error) {
        state.errorMessage = error.error;
      } else {
        state.errorMessage = "No data in the response.";
      }
      state.isWaiting = false;
    });
    builder.addCase(getTopSimilarAdVideos.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.isWaiting = false;
    });

    builder.addCase(buildReport.pending, (state, action) => {
      state.isWaiting = true;
      state.errorMessage = null;
    });
    builder.addCase(buildReport.fulfilled, (state, action) => {
      const { data, error } = action.payload;
      if (data) {
        if (data['status'] === 'report_available') {
          state.currentStep = LANDING_STEP.REPORT_AVAILABLE;
          state.reportId = data['report_id'];
        }
      } else if (error) {
        state.errorMessage = error.error;
      } else {
        state.errorMessage = "No data in the response.";
      }
      state.isWaiting = false;
    });
    builder.addCase(buildReport.rejected, (state, action) => {
      state.errorMessage = defaultErrorMessage
      state.isWaiting = false;
    });
  }
});

export const { landingNextStep } = landingSlice.actions;
export const landingReducer = landingSlice.reducer;
